/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../scss/app.scss');
require('../scss/plugins.css');
require('../scss/responsive.css');
require('../owl.video.play.png');

require('../bower_components/simplebar/dist/simplebar.css');
require('../bower_components/simplebar/dist/simplebar.js');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
var $ = require('jquery');
window.$ = $;
window.jQuery = $;

function menuItemChildren() {
    
        $('.menu-item-has-children >a').on('click', function () {
            if ($(window).width() < 1025) {
                $('.sub-menu.active').not($(this).next('.sub-menu')).slideUp().removeClass('active');
                $(this).next('.sub-menu').slideToggle().toggleClass('active');
            }
        });
        $(document).on('click', function (e) {
            if ($(window).width() < 1025) {
                if ($(e.target).closest('.menu-item-has-children >a').length === 0) {
                    $('.sub-menu.active').slideUp().removeClass('active');
                }
            }
        });
    
        $('.menu-item-has-children >a').on('mouseover', function () {
            if ($(window).width() > 1024) {
                $(this).next('.sub-menu').slideToggle().addClass('active');
            }
        });

        $('.menu-item-has-children').on('mouseleave', function () {
            if ($(window).width() > 1024) {
                $('.sub-menu.active').slideUp().removeClass('active');  
            }  
        });
    
}

function menuMobile() {
    $('.trigger-nav').on('click', function() {
        $(this).toggleClass('open');
        $('.main-menu').slideToggle();
    });
}

menuItemChildren();
menuMobile();


$(window).on('resize', function () {
    $('header ul').removeAttr('style');
    if ($(window).width() > 767) {
        $('.trigger-nav').removeClass('open');
    }
});
